// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../../@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-articles-category-template-tsx": () => import("./../../@narative/gatsby-theme-novela/src/templates/articles-category.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-articles-category-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("./../../@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-author-template-tsx": () => import("./../../@narative/gatsby-theme-novela/src/templates/author.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-author-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

